<template>

<div :style="{'background-image': 'url(img/praga_'+value+'.jpg)' }">
</div>
</template>

<script>

export default {
    props: ["value"],
}
</script>

<style>
div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    position: relative;
    width: 100%;
}
</style>
