<template>
<div style="background-image: url(img/praga_back.png);">
  <header>
    <h1>Praga Caput Regni</h1>
    <h3>Solo vs. Peter Parler</h3>
  </header>
  <a class="shuffle">klicken zum mischen</a>
</div>
</template>

<script>
export default {
}
</script>

<style scoped>
div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    width: 100%;
}

header {
    margin-top: 4rem;
    text-align: center;
    color: white;
}
h1 {
}

a.shuffle {
    bottom: 2rem;
    color: white;
    font-size: 2rem;
    margin-left: auto;
    position: absolute;
    text-align: center;
    width: 100%;
}

</style>
