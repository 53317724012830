<template>
  <div class="copyright">
    <a class="toggle" v-if="!showModal" @click.prevent="showModal = true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        shape-rendering="geometricPrecision"
      >
        <path
          d="M11 9h2a1 1 0 011 1
    1 1 0 002 0 3 3 0 00-3-3h-2a3 3 0 00-3 3v4a3 3 0 003 3h2a3 3 0
    003-3 1 1 0 00-2 0 1 1 0 01-1 1h-2a1 1 0 01-1-1v-4a1 1 0
    011-1zm1-7a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8 8 0
    01-8 8z"
        />
      </svg>
    </a>

    <v-modal v-model="showModal">
      <template v-slot:title>Solomodus vs. Peter Parler</template>
      <p>
        In dieser offiziellen Solovariante trittst du gegen
        <a
          href="https://de.wikipedia.org/wiki/Peter_Parler"
          target="_blank"
          rel="noopener"
        >
          Peter Parler
        </a>
        an, den berühmtesten Architekten im Reiche von Karl IV.
      </p>
      <p>
        Die
        <a
          href="http://deliciousgames.org/image/catalog/rules/PRAGA-solo-rules-v2-white-bgr.pdf"
          target="_blank"
          rel="noopener"
        >
          Spielregeln
        </a>

        dazu findest du auf der
        <a
          href="http://deliciousgames.org/praga-caput-regni-en"
          target="_blank"
          rel="noopener"
        >
          Webseite des Verlags </a
        >.
      </p>
      <p style="font-size: larger; font-weight: 600">
        Statt die 5 Karten ausdrucken zu müssen, kannst du auf dieser Webseite
        einfach dein Smartphone verwenden.
      </p>
      <p>
        Alle Rechte an den Bildern liegen bei Delicious Games, die hier mit
        freundlicher Genehmigung verwendet werden.
      </p>
      <p style="font-size: 0.9em">
        Kontakt:
        <a href="mailto:peterparler@luniki.de">peterparler@luniki.de</a>
      </p>
    </v-modal>
  </div>
</template>

<script>
import VModal from "./VModal.vue";

export default {
  data: () => ({
    showModal: false,
  }),
  components: { VModal },
};
</script>

<style scoped>
div.copyright {
  align-items: end;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
}

a.toggle {
  background-color: rgba(250, 250, 250, 0.1);
  font-size: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

a.toggle:hover svg {
  fill: lightblue;
}

svg {
  fill: white;
  height: 2rem;
  width: 2rem;
}

div.dialog {
  background-color: white;
  height: 100%;
  width: 100%;
}
</style>
