<template>
  <div id="app">
    <copyright></copyright>
    <card v-if="deck.length" :value="deck[0]" @click.native="pop" />
    <empty-deck v-else @click.native="shuffle" />
  </div>
</template>

<script>
import Card from "./components/Card.vue";
import Copyright from "./components/Copyright.vue";
import EmptyDeck from "./components/EmptyDeck.vue";
import shuffle from "lodash/shuffle";

export default {
  name: "App",

  data() {
    return {
      deck: [],
    };
  },

  methods: {
    pop() {
      if (this.deck.length) {
        const [, ...rest] = this.deck;
        this.deck = rest;
      }
    },

    shuffle() {
      this.deck = shuffle([0, 1, 2, 3, 4]);
    },
  },

  components: {
    Card,
    Copyright,
    EmptyDeck,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
}

#app {
  display: flex;
  font-style: italic;
  justify-content: center;
  height: 100%;
}
</style>
